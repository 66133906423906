<template>
  <div>
    <!-- <ModalLoading ref="modalLoading" :hasClose="false" /> -->
    <b-modal
      v-model="isShowModal"
      no-fade
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div class="text-center">
        <img
          src="@/assets/images/loading.svg"
          alt="loading"
          class="my-2 loading-icon"
        />
        <div class="textc-primary">กรุณารอสักครู่</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import ModalLoading from "@/components/modal/alert/ModalLoading";

export default {
  components: {
    // ModalLoading
  },
  data() {
    return {
      isShowModal: false
    };
  },
  async created() {
    this.isShowModal = true;
    // this.$refs.modalLoading.show();

    if (this.$route.params.sessionId) {
      await this.$axios
        .get(
          this.$chatBaseUrl + "/session/decode/" + this.$route.params.sessionId
        )
        .then(response => {
          if (response.data.result === 1) {
            this.$cookies.set(
              "d-order-session-token",
              response.data.detail.token,
              60 * 60 * 24 * 30
            );
            var timestamp = new Date().getTime();
            this.$cookies.set("d-order-timestamp", timestamp);
            this.$store.dispatch("setTimestamp", timestamp);
            this.isShowModal = false;

            // this.$router.replace({
            //   path: "/chat",
            //   query: { brandID: this.$route.query.brandID },
            // });
            window.location.href = `/chat/?brandID=${this.$route.query.brandID}`;
          } else {
            this.isShowModal = false;

            alert(response.data.message);
            window.location = this.$backofficeUrl;
          }
        })
        .catch(error => {
          console.log("error", error);
          this.isShowModal = false;
          window.location = this.$backofficeUrl;
        });
    } else {
      window.location = this.$backofficeUrl;
    }
  },
  methods: {}
};
</script>

<style></style>
